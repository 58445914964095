

.page-settings {
  .control-form {
    padding: 30px 50px 50px 20px;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
  }

  .btn-submit {
    width: 100px;
    margin-left: 50px;
  }

  .spin {
    position: relative;
    left: 50%;
    margin-left: -25px;
  }
}
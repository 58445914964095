
.page-charts {

    .chart-title {
        max-width: 1400px;
        margin: 20px 0 15px;
        padding: 10px;
        color: #0c8cfa;
        font-size: 16px;
        line-height: 2;

        box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
    }
}
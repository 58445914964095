

.time-price-wrap {
  &:hover {
    background-color: #f4f4f4;
  }
}

.time-price-input {
  margin: 8px 0;
  &+.time-price-input {
    margin-left: 15px;
  }
}

.page-home {


  .card {
    //width: 460px;
    max-width: 5200px;

    padding: 10px;

    margin: 10px;

    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);

    border-radius: 4px;

    .card-title {
      padding-left: 10px;

      //border-left: 3px solid #1890ff;
      background-color: #f6f6f6;
      line-height: 2.6;
    }

    .content {
      padding: 20px 10px;

      line-height: 36px;
      //height: 360px;
      //background-color: #f8f8f8;

      .label {
        width: 80px;
        text-align: right;
      }

      .bid-bar {
        position: relative;
        height: 16px;
        width: 300px;
        margin: 0 10px;
        border-radius: 8px;

        max-width: 320px;

        &.primary {
          background-image: linear-gradient(to right, #75f9f9, #77c0ff);
        }

        &.success {
          background-image: linear-gradient(to right, #0DFFBB , #00D69D);
        }

        &.error {
          background-image: linear-gradient(to right, #F9B575 , #FF77AF);
        }

        &:before {
          content: '';
          opacity: 0.6;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-image: inherit;
          filter: blur(5px);
        }
      }
    }

    //.pool {
    //  width: 172px;
    //  height: 172px;
    //
    //  color: #f8387d;
    //
    //  &.spec {
    //    margin-left: 10px;
    //    color: #0dc93c;
    //  }
    //
    //  img {
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //    //animation: 5s rotate linear infinite;
    //  }
    //
    //  .label {
    //    top: -22px;
    //  }
    //
    //  .title {
    //  }
    //
    //  .subtitle {
    //    padding-top: 6px;
    //  }
    //
    //}
  }
}

//@keyframes rotate {
//  0% {
//    transform: rotate(0);
//  }
//  100% {
//    transform: rotate(360deg);
//  }
//}



.page-proxy-list {

  .ant-upload-list {
    display: none;
  }

  .drop-detail {
    margin: 5px 0;

    display: flex;

    justify-content: space-between;

    i {
      display: block;
      position: relative;
      width: 34px;
      text-align: center;
      margin-right: -5px;
      margin-left: 5px;


      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        bottom: -1px;

        border-left: 1px solid #fff;

        transform: scaleX(0.5);
      }
    }
  }
}
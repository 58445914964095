
.page-bid-list {

  .ant-upload-list {
    display: none;
  }

  .drop-down-panel {
    position: absolute;
    left: 112px;
    right: 12px;
    top: 40px;

    padding: 10px 0;

    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
    z-index: 99;

    background-color: #fff;

    li {
      padding: 0 10px;
      line-height: 25px;
      cursor: pointer;
      &:hover {
        background-color: #e4f1ff;
      }
    }
  }

  .fodder {
    position: relative;
    top: -2px;
    left: 2px;
    display: inline-block;
    padding: 1px 4px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #ff4500;
    transform: scale(0.7);
  }
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.demo-download-link {
  position: fixed;
  right: 40px;
  bottom: 66px;
}